.integrante {
  color: #303030;
  padding: 10px;
}

.integrante-nome {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  padding-top: 16px;
}

@media (min-width: 992px) {
  .integrante-nome {
    font-size: 20px;
  }
}

.integrante-div-imagem {
  text-align: center;
}

.integrante-imagem {
  display: inline-block;
  border-radius: 100%;
  width: 88px;
  height: 88px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.integrante-funcao {
  padding-top: 2px;
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
}

@media (min-width: 992px) {
  .integrante-funcao {
    font-size: 18px;
  }
}

.integrante-descricao {
  padding-top: 16px;
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
}

@media (min-width: 992px) {
  .integrante-descricao {
    font-size: 18px;
  }
}
