@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
body {
    font-family: Roboto, "Helvetica Neue", Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" ;
    line-height: 1.5;
}
.nav--brothertec{
    line-height: 1;
    background-color: #fff;    
    padding-top: 2px;
    padding-bottom: 2px;
}

@media (min-width: 992px){
    .nav--brothertec{
        padding-top: 5px; 
        padding-bottom: 5px; 
    }
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
}

.nav-imagem {
    width: 205px;
    height: 50px;
}

@media (min-width: 992px){
    .nav-imagem {
        width: 293px;
        height: 72px;
    }
}

.nav--brothertec .nav-link { 
    color: #5F5F5E;
    font-size: 16px;
}

@media (min-width: 992px){
    .nav--brothertec .nav-link { 
        font-size: 20px;
    }
}

.nav--brothertec .nav-link.selected { 
    color: #0063B1;
    font-weight: 500;
}

.nav--brothertec .navbar-toggler {
    color: #0063B1;
    border: 1px solid #D6D6D6;
}

@media(min-width: 992px){
    .nav-item--brothertec{
        padding-left: 48px;
    }
}


.banner {
    background-image: url(/static/media/bg-header-min.069d0d41.jpg);
    background-repeat: repeat;    
    width: 100%; 
    line-height: 1.1;
    color: #FFFFFF;
    font-weight: 500;
    padding-top: 60px;
    margin-top: 54px;
    
}

@media (min-width: 992px) {
    .banner {
        margin-top: 81px;
        padding-bottom: 30px;
    }
}

div .titulo{
    padding-bottom: 42px;
}

.banner h1{
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    
}

@media (min-width: 992px) {
    .banner h1{
        font-size: 40px;
    }
}

.banner h1 span{
    font-size: 35px;
}

@media (min-width: 992px) {
    .banner h1 span{
        font-size: 62px;
    }
}

.icon-container{
    padding-top: 42px;
}

.icon-div{
    flex: 1 1;
}

.icon-title{
    display: block;
    text-align: center;
    padding-bottom: 16px;
    font-size: 20px;
}

@media (min-width: 992px){
    .icon-title{
        font-size: 24px;
    }
}


.icon-image{
    display: block;
    margin: auto;
    width: 64px;
    height: 64px;
    margin-bottom: 72px;
    
}

@media (min-width: 992px){
    .icon-image{
        width: 128px;
        height: 128px;
    }
}

.website{
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
}

@media (min-width: 992px){
    .website{
        width: 128px;
        height: 128px;
    }
}




.integrante {
  color: #303030;
  padding: 10px;
}

.integrante-nome {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  padding-top: 16px;
}

@media (min-width: 992px) {
  .integrante-nome {
    font-size: 20px;
  }
}

.integrante-div-imagem {
  text-align: center;
}

.integrante-imagem {
  display: inline-block;
  border-radius: 100%;
  width: 88px;
  height: 88px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.integrante-funcao {
  padding-top: 2px;
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
}

@media (min-width: 992px) {
  .integrante-funcao {
    font-size: 18px;
  }
}

.integrante-descricao {
  padding-top: 16px;
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
}

@media (min-width: 992px) {
  .integrante-descricao {
    font-size: 18px;
  }
}

.section-sobre {
  padding: 32px 0 32px 0;
}

@media (min-width: 992px) {
  .section-sobre {
    padding: 64px 0 64px 0;
  }
}

.separador-mobile {
  width: 64px;
  border: 1px solid #c4c4c4;
  background-color: #c4c4c4;
}

@media (min-width: 768px) {
  .separador-mobile {
    display: none;
  }
}

.sobre-titulo {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.5;
  padding-bottom: 24px;
  margin: 0;
}

@media (min-width: 992px) {
  .sobre-titulo {
    font-weight: 500;
    font-size: 40px;
  }
}

.sobre-descricao {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}

@media (min-width: 992px) {
  .sobre-descricao {
    font-weight: 500;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .l-integrante {
    margin-bottom: 20px;
  }
}

/* .card--brothertec{
    max-width: 288px;
} */
/* @media (min-width: 576px) {
    .card--brothertec{
        max-width: 536px;
    }
} */
.imagem-card{
    border-bottom: 1px solid #C4C4C4;

}

.badge-card{
    width: auto;
    background-color:#818A91;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 16px;
}

@media (min-width: 992px){
    .badge-card{
        margin-top: 4px;
        margin-bottom: 24px;
   

    }
}

.titulo-card{
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 12px;
    color: #303030;
}

@media (min-width: 992px){
    .titulo-card{
        font-weight: bold;
        font-size: 20px;
        line-height: 1.1;
        margin-bottom: 24px;
    }
}

.texto-card{
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 992px){
    .texto-card{
        font-size: 18px;
        line-height: 150%;
    }
}

.botao-card{
    background-color: #0063B1;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}
.projetos{
    background-color: #E4F3FF;
    padding: 32px 0;
}

@media (min-width: 992px) {
    .projetos{
        padding: 64px 0;
    }
}
.projeto-titulo{
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
    padding-bottom: 24px;
}

@media (min-width: 992px) {
    .projeto-titulo{
        font-weight: 500;
        font-size: 40px;
        padding-bottom: 33px;
    }
}
.footer-brothertec {
    text-align: center;
    padding: 10px 0;
}

.footer-brothertec-text {
    font-size: 14px;
    line-height: 1.5;
    color: #303030;
}
.contato{
    background-image: url(/static/media/bg-header.471d0623.png);
    background-repeat: repeat; 
    padding-top: 32px;
    padding-bottom: 32px; 
}
  
.contato-titulo{
    
    padding-bottom: 25px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
}

@media (min-width: 992px){
    .contato-titulo{
        font-size: 40px;
    }
}

.form-contato{
margin-bottom: 40px;
}

@media (min-width: 992px){
    .tamanho-contato{
        max-width: 730px;
        margin-left: auto;
        margin-right: auto;

    }
}

.btn-contato{
    color: #373A3C;
    font-size: 16px;
    line-height: 21px;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 4px;

}

.icone-contato{
    width: 40px;
    height: 52px;
    font-size: 45px;
    line-height: 52px;
    color: #FFFFFF;
   
}

.contato-icone{
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 0;
}

.icone-meio{
    margin-left: 84px;
    margin-right: 84px;
    
}

.link-icone{
    color: inherit;
}

.link-icone:visited{
    color: inherit;
}
.link-icone:hover{
    color: inherit;
}
.link-icone:active{
    color: inherit;
}
