/* .card--brothertec{
    max-width: 288px;
} */
/* @media (min-width: 576px) {
    .card--brothertec{
        max-width: 536px;
    }
} */
.imagem-card{
    border-bottom: 1px solid #C4C4C4;

}

.badge-card{
    width: auto;
    background-color:#818A91;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin-bottom: 16px;
}

@media (min-width: 992px){
    .badge-card{
        margin-top: 4px;
        margin-bottom: 24px;
   

    }
}

.titulo-card{
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 12px;
    color: #303030;
}

@media (min-width: 992px){
    .titulo-card{
        font-weight: bold;
        font-size: 20px;
        line-height: 1.1;
        margin-bottom: 24px;
    }
}

.texto-card{
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 992px){
    .texto-card{
        font-size: 18px;
        line-height: 150%;
    }
}

.botao-card{
    background-color: #0063B1;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}