.contato{
    background-image: url("../../img/bg-header.png");
    background-repeat: repeat; 
    padding-top: 32px;
    padding-bottom: 32px; 
}
  
.contato-titulo{
    
    padding-bottom: 25px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
}

@media (min-width: 992px){
    .contato-titulo{
        font-size: 40px;
    }
}

.form-contato{
margin-bottom: 40px;
}

@media (min-width: 992px){
    .tamanho-contato{
        max-width: 730px;
        margin-left: auto;
        margin-right: auto;

    }
}

.btn-contato{
    color: #373A3C;
    font-size: 16px;
    line-height: 21px;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 4px;

}

.icone-contato{
    width: 40px;
    height: 52px;
    font-size: 45px;
    line-height: 52px;
    color: #FFFFFF;
   
}

.contato-icone{
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 0;
}

.icone-meio{
    margin-left: 84px;
    margin-right: 84px;
    
}

.link-icone{
    color: inherit;
}

.link-icone:visited{
    color: inherit;
}
.link-icone:hover{
    color: inherit;
}
.link-icone:active{
    color: inherit;
}