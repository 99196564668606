.projetos{
    background-color: #E4F3FF;
    padding: 32px 0;
}

@media (min-width: 992px) {
    .projetos{
        padding: 64px 0;
    }
}
.projeto-titulo{
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
    padding-bottom: 24px;
}

@media (min-width: 992px) {
    .projeto-titulo{
        font-weight: 500;
        font-size: 40px;
        padding-bottom: 33px;
    }
}