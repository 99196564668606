.nav--brothertec{
    line-height: 1;
    background-color: #fff;    
    padding-top: 2px;
    padding-bottom: 2px;
}

@media (min-width: 992px){
    .nav--brothertec{
        padding-top: 5px; 
        padding-bottom: 5px; 
    }
}

.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
}

.nav-imagem {
    width: 205px;
    height: 50px;
}

@media (min-width: 992px){
    .nav-imagem {
        width: 293px;
        height: 72px;
    }
}

.nav--brothertec .nav-link { 
    color: #5F5F5E;
    font-size: 16px;
}

@media (min-width: 992px){
    .nav--brothertec .nav-link { 
        font-size: 20px;
    }
}

.nav--brothertec .nav-link.selected { 
    color: #0063B1;
    font-weight: 500;
}

.nav--brothertec .navbar-toggler {
    color: #0063B1;
    border: 1px solid #D6D6D6;
}

@media(min-width: 992px){
    .nav-item--brothertec{
        padding-left: 48px;
    }
}

