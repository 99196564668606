.banner {
    background-image: url("../../img/bg-header-min.jpg");
    background-repeat: repeat;    
    width: 100%; 
    line-height: 1.1;
    color: #FFFFFF;
    font-weight: 500;
    padding-top: 60px;
    margin-top: 54px;
    
}

@media (min-width: 992px) {
    .banner {
        margin-top: 81px;
        padding-bottom: 30px;
    }
}

div .titulo{
    padding-bottom: 42px;
}

.banner h1{
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    
}

@media (min-width: 992px) {
    .banner h1{
        font-size: 40px;
    }
}

.banner h1 span{
    font-size: 35px;
}

@media (min-width: 992px) {
    .banner h1 span{
        font-size: 62px;
    }
}

.icon-container{
    padding-top: 42px;
}

.icon-div{
    flex: 1;
}

.icon-title{
    display: block;
    text-align: center;
    padding-bottom: 16px;
    font-size: 20px;
}

@media (min-width: 992px){
    .icon-title{
        font-size: 24px;
    }
}


.icon-image{
    display: block;
    margin: auto;
    width: 64px;
    height: 64px;
    margin-bottom: 72px;
    
}

@media (min-width: 992px){
    .icon-image{
        width: 128px;
        height: 128px;
    }
}

.website{
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
}

@media (min-width: 992px){
    .website{
        width: 128px;
        height: 128px;
    }
}



